import { useEffect, useState } from "react";
import axios from "axios";
import SwaggerUI from "swagger-ui-react";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../hooks/useAuthHeader";
import Unauthorized from "../global/components/Unauthorized";

import "swagger-ui-react/swagger-ui.css";

export default function Swagger() {
	const authHeader = useAuthHeader();
	const [swaggerSpec, setSwaggerSpec] = useState(null);
	const [isUnauthorized, setIsUnauthorized] = useState(false);

	const getSwaggerSpec = async () => {
		try {
			const response = await axios.get(apiRoutes.getSwagger, {
				headers: authHeader,
			});
			setSwaggerSpec(response.data);
		} catch (err) {
			if (err.response && err.response.status === 403) {
				setIsUnauthorized(true);
			} else {
				console.error("Error fetching Swagger spec:", err);
			}
		}
	};

	useEffect(() => {
		getSwaggerSpec();
	}, []);

	if (isUnauthorized) {
		return <Unauthorized />;
	}

	return swaggerSpec ? <SwaggerUI spec={swaggerSpec} /> : null;
}