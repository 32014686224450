import { DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton, CoAppCloseIcon, CoAppDialog } from "../../styled/global.styled";
import CoAppMultiSelect from "../inputs/CoAppMultiSelect";
import CoAppTextField from "../inputs/CoAppTextField";

export default function CoAppCreateUserModal(props) {
	const {
		dialogOpen, availableRoles, userData,
		cancelClickHandler, confirmClickhandler, userDataChangeHandler
	} = props;
	return (
		<CoAppDialog open={dialogOpen} onClose={cancelClickHandler} PaperProps={{ sx: { borderRadius: "8px", padding: "8px", width: "400px" } }}>
			<DialogTitle variant="h6">Add user</DialogTitle>
			<CoAppCloseIcon onClick={cancelClickHandler} top={8} left={365} />
			<DialogContentText
				variant="body2"
				color="text.primary"
				sx={{ marginLeft: "25px", marginBottom: "-15px" }}
			>
				Create a new user for your organization.
			</DialogContentText>
			<DialogContent>
				<Stack>
					<CoAppTextField
						FormHelperTextProps={{ style: { color: "#DE3730" } }}
						label="First name"
						onChange={(e) => userDataChangeHandler("firstName", e.target.value)}
						error={userData.firstNameError !== ""}
						helperText={userData.firstNameError !== "" ? userData.firstNameError : "Required *"}
					/>
					<CoAppTextField
						FormHelperTextProps={{ style: { color: "#DE3730" } }}
						label="Last name"
						onChange={(e) => userDataChangeHandler("lastName", e.target.value)}
						error={userData.lastNameError !== ""}
						helperText={userData.lastNameError !== "" ? userData.lastNameError : "Required *"}
					/>
					<CoAppTextField
						FormHelperTextProps={{ style: { color: "#DE3730" } }}
						label="Email"
						onChange={(e) => userDataChangeHandler("email", e.target.value)}
						error={userData.emailError !== "" && userData.emailError !== "Invalid email"}
						helperText={userData.emailError !== "" ? userData.emailError : "Required *"}
					/>
				</Stack>
				<CoAppMultiSelect
					disabled={false}
					isFreeSolo={false}
					label="Role(s)"
					options={availableRoles.map((item) => item.name)}
					value={userData.roles.map((item) => item.name)}
					onChange={(e, value, reason) => userDataChangeHandler("roles", value)}
					hasToolTip={false}
					error={userData.roleError !== ""}
					helperText={userData.roleError !== "" ? userData.roleError : "Required *"}
				/>
			</DialogContent>
			<DialogActions sx={{ marginRight: "15px", marginBottom: "10px" }}>
				<CoAppCancelTextButton onClick={cancelClickHandler}>Cancel</CoAppCancelTextButton>
				<CoAppActionButton onClick={confirmClickhandler}>Save</CoAppActionButton>
			</DialogActions>
		</CoAppDialog >
	);
}