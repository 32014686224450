import { useState } from "react";
import { Info } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme } from "@mui/material";

import messages from "../../../../constants/messages";
import CoAppEmbeddedVideo from "../../../global/components/CoAppEmbeddedVideo";
import {
	CoAppCancelTextButton, CoAppCloseIcon, CoAppDestructiveActionButton,
	CoAppLightTooltip
} from "../../../global/styled/global.styled";

import { RoleValidationMissingPermissionsContainer } from "./styled/scim-wizard.styled";
import ssoScimVideoLinks from "./ssoScimVideoLinks";

export default function RoleValidationModal(props) {
	const {
		dialogOpen, confirmClickHandler, cancelClickHandler,
		roleMappingStatus, identityProviderName
	} = props;
	const theme = useTheme();

	const [showVideo, setShowVideo] = useState(false);
	const [showThumbnail, setShowThumbnail] = useState(false);

	const groupedPermissions = {};
	roleMappingStatus?.missingPermissions?.forEach(permission => {
		if (!groupedPermissions[permission.category]) {
			groupedPermissions[permission.category] = [];
		}
		groupedPermissions[permission.category].push(permission);
	});

	return (
		<Dialog open={dialogOpen} onClose={cancelClickHandler} PaperProps={{ sx: { borderRadius: "10px" } }}>
			<DialogTitle variant="h3" sx={{ marginLeft: "5px", marginTop: "15px" }}>
				{
					!roleMappingStatus.coappRoleStatus ?
						"You are not in any CoApp roles"
						:
						"You are not a super admin"
				}
			</DialogTitle>
			<CoAppCloseIcon onClick={cancelClickHandler} />
			<DialogContent>
				{
					roleMappingStatus.coappRoleStatus ?
						<Typography color="text.primary" variant="body1" sx={{ marginLeft: "10px" }}>
							{messages.SCIM_ROLE_SUPER_ADMIN_WARNING(roleMappingStatus.currentRolesWithPermissions.map(role => role.name))}
						</Typography>
						:
						<Typography color="text.primary" variant="body1" sx={{ marginLeft: "10px" }}>
							{messages.SCIM_ROLE_WARNING}
						</Typography>
				}
			</DialogContent>
			{
				!roleMappingStatus.superAdminStatus && roleMappingStatus.coappRoleStatus ?

					<RoleValidationMissingPermissionsContainer>
						{
							roleMappingStatus?.missingPermissions?.length > 0
								?
								<>
									These roles do not contain the following permissions:
									{
										Object.keys(groupedPermissions).map((category, index) => (
											<div key={category}>
												<Typography sx={{ marginTop: "15px", marginBottom: "15px" }} variant="h6">{category}</Typography>
												{
													groupedPermissions[category].map((item, index) => (
														<Stack key={index} direction="row" spacing={3} sx={{ marginTop: "10px" }}>
															<Typography>{item.friendlyName}</Typography>
															<CoAppLightTooltip title={item.description} placement="top">
																<Info sx={{ color: theme.palette.text._states.focusVisible }} />
															</CoAppLightTooltip>
														</Stack>
													))
												}
											</div>
										))
									}
								</>
								:
								null
						}
					</RoleValidationMissingPermissionsContainer>
					:
					null
			}
			{
				!roleMappingStatus.superAdminStatus && roleMappingStatus.coappRoleStatus ?
					<DialogContent>
						<Typography variant="body1" sx={{ marginTop: "15px" }}>
							{`If this isn't reflecting recent changes within ${identityProviderName}, start on-demand provisioning.`}
							<CoAppEmbeddedVideo
								iconCustomStyles={{ marginBottom: "-7px", marginLeft: "5px" }}
								showThumbnail={showThumbnail}
								showVideo={showVideo}
								setShowThumbnail={setShowThumbnail}
								setShowVideo={setShowVideo}
								iframeTitle="SCIM Wizard - On-Demand Provisioning"
								iframeSrc={ssoScimVideoLinks.provisionGroupWithMembersVideo}
								thumbnailSrc={ssoScimVideoLinks.provisionGroupWithMembersThumbnail}
								thumbnailAltText="Provision Group With Members Thumbnail"
							/>
						</Typography>
					</DialogContent>
					:
					null
			}
			<DialogActions sx={{ marginBottom: "20px", marginRight: "20px" }}>
				<CoAppCancelTextButton onClick={cancelClickHandler}>Cancel</CoAppCancelTextButton>
				<CoAppDestructiveActionButton onClick={confirmClickHandler}>Proceed</CoAppDestructiveActionButton>
			</DialogActions>
		</Dialog>
	);
}