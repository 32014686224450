import { AdvancedTriggerSidebarItem } from "../styled/rule-sidebars.styled";

export const AdvancedTriggerItemWrapper = (props) => {
	return (
		< AdvancedTriggerSidebarItem onClick={() => props.copyAdvancedTriggerToFormula(props.index, -1)}>
			{props.item.friendlyName}
			< div style={{
				position: "absolute",
				right: "10px",
				top: "50%",
				transform: "translateY(-50%)",
				visibility: "hidden",
				transition: "visibility 0.2s ease-in-out"
			}}>
			</div >
		</AdvancedTriggerSidebarItem >

	);
};