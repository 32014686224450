import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "@descope/react-sdk";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";

import AlertSnackbar from "./components/global/components/AlertSnackbar";
import store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import appRoutes from "./routes";
import { lightModeTheme } from "./theme";

const router = createBrowserRouter(appRoutes);

const root = ReactDOM.createRoot(document.getElementById("root"));

LicenseInfo.setLicenseKey("1a23526d9887f7585762a632d3c4983eTz0xMDM5MzIsRT0xNzY1MzMyODQ4MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

root.render(
	<AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}>
		<Provider store={store}>
			<ThemeProvider theme={lightModeTheme}>
				<CssBaseline />
				<AlertSnackbar />
				<RouterProvider router={router}>
					<App />
				</RouterProvider>
			</ThemeProvider>
		</Provider>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
