import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CheckOutlined, ErrorOutline } from "@mui/icons-material";
import {
	Divider, FormControlLabel, Stack,
	Switch, Typography
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useToastAlert from "../../../../hooks/useToastAlert";
import CoAppEmbeddedVideo from "../../../global/components/CoAppEmbeddedVideo";
import CoAppTextField from "../../../global/components/inputs/CoAppTextField";
import { CoAppActionButton, CoAppLightTooltip } from "../../../global/styled/global.styled";

export default function PluginHomePage() {
	const { pluginId } = useParams();
	const { state } = useLocation();
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();

	const [endpoint, setEndpoint] = useState("");
	const [plugin, setPlugin] = useState({ active: false });
	const [showThumbnail, setShowThumbnail] = useState(false);
	const [showVideo, setShowVideo] = useState(false);

	const setPluginEndpoint = () => {
		const pluginData = {
			endpoint: endpoint
		};
		axios.put(apiRoutes.setCustomerManagedPluginEndpoint(pluginId), pluginData, { headers: authHeader })
			.then(() => {
				setPlugin({ ...plugin, endpoint: endpoint });
			})
			.catch(err => {
				console.log(err);
			});
	};

	const togglePluginStatus = () => {
		axios.put(apiRoutes.togglePluginStatusForOrganization(plugin.organizationId, pluginId), {}, { headers: authHeader })
			.then(() => {
				setPlugin({ ...plugin, active: !plugin.active });
			})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, state.isActive ? messages.PLUGIN_DISABLED_ERROR_MSG : messages.PLUGIN_ENABLED_ERROR_MSG);
			});
	};

	const initPlugin = () => {
		axios.get(apiRoutes.getPlugin(pluginId), { headers: authHeader })
			.then((response) => {
				setPlugin(response.data);
				setEndpoint(response.data.endpoint);
			})
			.catch(err => {
				console.log(err);
			});
	};

	useEffect(() => {
		initPlugin();
	}, []);

	return (
		<Stack sx={{ padding: "25px" }} spacing={3}>
			<Stack>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h3">{`${state.name} plugin`}</Typography>
					<FormControlLabel
						label={
							plugin.active
								?
								<Typography variant="body2">Active</Typography>
								:
								<Typography variant="body2">Inactive</Typography>
						}
						labelPlacement="start"
						control={
							<CoAppLightTooltip title={plugin.active ? `Deactivate ${state.name}` : `Activate ${state.name}`} placement="right">
								<Switch
									checked={Boolean(plugin.active)}
									onChange={togglePluginStatus}
								/>
							</CoAppLightTooltip>
						}
					/>
				</Stack>
				<Typography variant="body1">{`The ${state.name} plugin is currently ${plugin.active ? "enabled" : "disabled"} for your organization. Manage granular assignment in the groups tab.`}</Typography>
				<Typography variant="body1">{`Manage features of ${state.name} below.`}</Typography>
			</Stack>
			<Divider sx={{ width: "56%" }} />
			<Stack>
				<Stack direction="row" spacing={2}>
					<Typography variant="h4">API key status</Typography>
					<CoAppEmbeddedVideo
						showVideo={showVideo}
						setShowVideo={setShowVideo}
						showThumbnail={showThumbnail}
						setShowThumbnail={setShowThumbnail}
						iframeTitle="Plugin API Configuration Video"
						iframeSrc={plugin.instructionalVideoLink}
						thumbnailSrc={plugin.instructionalThumbnailLink}
						thumbnailAltText="Plugin API Configuration Thumbnail"
						thumbnailOverlayText={`How to configure an API key for the ${state.name} plugin`}
					/>
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1}>
					{
						plugin.apiKeyConfigured
							?
							<CheckOutlined sx={{ height: "48px", width: "48px", color: "#2E7D32" }} />
							:
							<ErrorOutline sx={{ height: "48px", width: "48px", color: "#DB7A74" }} />
					}
					{
						plugin.apiKeyConfigured
							?
							<Typography variant="body1">An API key has been configured for this plugin.</Typography>
							:
							<Typography variant="body1">An API key has not been configured for this plugin.</Typography>
					}
				</Stack>
			</Stack>
			<Divider sx={{ width: "56%" }} />
			<Stack>
				<Typography variant="h4">API Endpoint</Typography>
				<CoAppTextField
					value={endpoint}
					onChange={(e) => setEndpoint(e.target.value)}
					label={`${state.name} API Endpoint`}
					sx={{ width: "35%" }}
				/>
				<CoAppActionButton onClick={setPluginEndpoint} disabled={endpoint.length === 0 || plugin.endpoint === endpoint} sx={{ width: "40px", marginTop: "10px" }}>Save</CoAppActionButton>
			</Stack>
		</Stack >
	);
}