import { useState } from "react";
import { Stack, Typography } from "@mui/material";

import CoAppEmbeddedVideo from "../../../../global/components/CoAppEmbeddedVideo";
import CoAppTextField from "../../../../global/components/inputs/CoAppTextField";
import ssoScimVideoLinks from "../ssoScimVideoLinks";
import { ScimWizardStepContainer } from "../styled/scim-wizard.styled";

export default function Step3(props) {
	const {
		samlClaims, setSamlClaims, samlClaimsError,
		setSamlClaimsError, metadataUrl, setMetadataUrl,
		metadataError, setMetadataError
	} = props;

	const [showVideo, setShowVideo] = useState(false);
	const [showThumbnail, setShowThumbnail] = useState(false);

	const handleEmailClaimNameChange = (e) => {
		setSamlClaims({ ...samlClaims, email: e.target.value });
		setSamlClaimsError({ ...samlClaimsError, email: "" });
	};

	const handleGivenNameClaimNameChange = (e) => {
		setSamlClaims({ ...samlClaims, givenName: e.target.value });
		setSamlClaimsError({ ...samlClaimsError, givenName: "" });
	};

	const handleSurnameClaimNameChange = (e) => {
		setSamlClaims({ ...samlClaims, surname: e.target.value });
		setSamlClaimsError({ ...samlClaimsError, surname: "" });
	};

	const handleMetadataUrlChange = (e) => {
		setMetadataUrl(e.target.value);
		setMetadataError("");
	};

	return (
		<ScimWizardStepContainer spacing={{ xxl: 4, xl: 4, lg: 2, sm: 1 }}>
			<Typography variant="h3_5">Enter SAML Claims</Typography>
			<Stack direction="row" spacing={{ xxl: 1, xl: 1, lg: 0.25 }}>
				<Typography variant="body1">Enter SAML token claims and App Federation Metadata Url below to complete SSO configuration.</Typography>
				<CoAppEmbeddedVideo
					showVideo={showVideo}
					setShowVideo={setShowVideo}
					showThumbnail={showThumbnail}
					setShowThumbnail={setShowThumbnail}
					iframeTitle="SAML Claims Video"
					iframeSrc={ssoScimVideoLinks.configureClaimsAndMetatdataUrlVideo}
					thumbnailSrc={ssoScimVideoLinks.configureClaimsAndMetadataUrlThumbnail}
					thumbnailAltText="SAML Claims Video Thumbnail"
				/>
			</Stack>
			<CoAppTextField
				FormHelperTextProps={{ style: { color: "#DE3730" } }}
				label="Email Claim Name"
				placeholder="Email Claim Name"
				value={samlClaims.email}
				onChange={handleEmailClaimNameChange}
				error={samlClaimsError.email !== ""}
				helperText={samlClaimsError.email !== "" ? samlClaimsError.email : "Required *"}
				sx={{ width: { xxl: "30%", sm: "45%" } }}
			/>
			<CoAppTextField
				FormHelperTextProps={{ style: { color: "#DE3730" } }}
				label="Given Name Claim Name"
				placeholder="Given Name Claim Name"
				value={samlClaims.givenName}
				onChange={handleGivenNameClaimNameChange}
				error={samlClaimsError.givenName !== ""}
				helperText={samlClaimsError.givenName !== "" ? samlClaimsError.givenName : "Required *"}
				sx={{ width: { xxl: "30%", sm: "45%" } }}
			/>
			<CoAppTextField
				FormHelperTextProps={{ style: { color: "#DE3730" } }}
				label="Surname Claim Name"
				placeholder="Surname Claim Name"
				value={samlClaims.surname}
				onChange={handleSurnameClaimNameChange}
				error={samlClaimsError.surname !== ""}
				helperText={samlClaimsError.surname !== "" ? samlClaimsError.surname : "Required *"}
				sx={{ width: { xxl: "30%", sm: "45%" } }}
			/>
			<CoAppTextField
				FormHelperTextProps={{ style: { color: "#DE3730" } }}
				label="App Federation Metadata Url"
				placeholder="App Federation Metadata Url"
				value={metadataUrl}
				onChange={handleMetadataUrlChange}
				error={metadataError !== ""}
				helperText={metadataError !== "" ? metadataError : "Required *"}
				sx={{ width: { xxl: "30%", sm: "45%" } }}
			/>
		</ScimWizardStepContainer >
	);
}