import { WizardStepIconRoot } from "../styled/rule-wizard.styled";

export default function WizardStepIcon(props) {
	const { active, completed, className } = props;
	if (completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-completedIcon" />
			</WizardStepIconRoot>);
	} else if (active && !completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-active-incomplete" />
			</WizardStepIconRoot>
		);
	} else if (!active && !completed) {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle-inactive-incomplete" />
			</WizardStepIconRoot>
		);
	} else {
		return (
			<WizardStepIconRoot ownerState={{ active, completed }} className={className}>
				<div className="WizardStepIcon-circle" />
			</WizardStepIconRoot>
		);
	}
}