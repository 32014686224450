/**
 * Generates fake data for the data grids to display the skeleton cell while the real data is fetched.
 * @param {*} columns columns of the data grid
 * @returns fake rows for the data grid
 */
export const getDataGridFakeData = (columns) => {
	let row = { isDummyRow: true };
	columns.forEach((column) => {
		row = {
			...row,
			[`${column}`]: ""
		};
	});

	const rows = Array.from({ length: 25 }, () => { return { ...row, id: Math.random(), value: "" }; });
	return rows;
};

/**
 * Custom comparator function for comparing name fields in user specific data grids.
 * @param {*} v1 first value to compare
 * @param {*} v2 second value to compare
 * @returns negative or positive number if the first value is less or greater than the second value, respectively
 */
export const customNameComparator = (v1, v2) => {
	const stringifiedV1 = JSON.stringify(v1);
	const stringifiedV2 = JSON.stringify(v2);
	if (!stringifiedV1 && !stringifiedV2) return;
	return stringifiedV1.localeCompare(stringifiedV2);
};