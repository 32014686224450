import { Typography } from "@mui/material";

import prettifyDate from "../../../../helpers/prettifyDate";
import { CoAppDataGridTextCellContainer } from "../../styled/global.styled";

export default function CoAppDataGridDateTimeCell(props) {
	const { value, isCoAppSuperAdmin = false, isTrashed = false } = props;
	return (
		<CoAppDataGridTextCellContainer>
			<Typography variant="body2" color={isTrashed || isCoAppSuperAdmin ? "text.disabled" : "text.primary"}>
				{value && prettifyDate(value)}
			</Typography>
		</CoAppDataGridTextCellContainer>
	);
}