import { useLayoutEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";

import { CoAppDataGridTextCellContainer, CoAppGroupList, CoAppLightTooltip } from "../../styled/global.styled";

export default function CoAppDataGridListCell(props) {
	const {
		items = [], resource, isUser = false,
		isCoAppSuperAdmin = false
	} = props;

	const [isOverflowing, setIsOverflowing] = useState(false);
	const [localItems, setLocalItems] = useState(items);
	const [moreCount, setMoreCount] = useState(0);
	const ref = useRef();

	/**
	 * This calculates the amount of items we need to slice from the array based on the height of the container, which is set to 50 pixels.
	 * In addition, we base it off of the lineHeight of the actual text, which is set to 16 pixels   
	 */
	const calculateSliceCount = () => {
		const lineHeight = 16; //Set to the defined lineHeight of the component
		const sliceCount = Math.floor((ref.current.clientHeight / lineHeight));
		return sliceCount;
	};

	const getTooltipTitle = () => {
		if (isUser) {
			return items.map(item => {
				if (item.firstname && item.lastname) {
					return item.firstname + " " + item.lastname;
				} else {
					return item.email;
				}
			}).join(", ");
		} else {
			return items.map(item => item.name ? item.name : item).join(", ");
		}
	};
	/**
	 * Handles when to show the "+n" text with tooltip.
	 * Also handles updating role/group list when the content is updated. 
	 */
	const handleLayoutEffect = () => {
		if (items && items.length > 0) {
			if (ref?.current?.clientHeight < ref?.current?.scrollHeight) {
				setIsOverflowing(true);
				const sliceCount = calculateSliceCount();
				setLocalItems(items.slice(0, sliceCount));
				setMoreCount(items.length - sliceCount);
			} else if (isOverflowing) {
				const sliceCount = calculateSliceCount();
				setLocalItems(items.slice(0, sliceCount));
				setMoreCount(items.length - sliceCount);
			} else {
				setLocalItems(items);
			}
		}
	};

	const getItemsToDisplay = () => {
		if (isUser) {
			return localItems.map(item => {
				if (item.firstname && item.lastname) {
					return item.firstname + " " + item.lastname;
				} else {
					return item.email;
				}
			}).join(", ");
		} else {
			return localItems.map(item => item.name ? item.name : item).join(", ");
		}
	};

	useLayoutEffect(() => {
		handleLayoutEffect();
	}, [ref, items]);

	return (
		<div>
			{items && items.length > 0 ? (
				<>
					{
						!isOverflowing ?
							<CoAppGroupList ref={ref} superadmin={isCoAppSuperAdmin ? "true" : "false"}>
								{getItemsToDisplay()}
							</CoAppGroupList>
							:
							<CoAppLightTooltip title={getTooltipTitle()} opacity="false">
								<CoAppGroupList ref={ref} overflowing={isOverflowing} count={moreCount} superadmin={isCoAppSuperAdmin ? "true" : "false"}>
									{getItemsToDisplay()}
								</CoAppGroupList>
							</CoAppLightTooltip>
					}
				</>
			) : (
				<CoAppDataGridTextCellContainer>
					<Typography variant="body2" color={isCoAppSuperAdmin ? "text.disabled" : "text.primary"}>No {resource}</Typography>
				</CoAppDataGridTextCellContainer>
			)}
		</div>
	);
}