import {
	Close, Edit, ExpandMore,
	Help, PlayArrow, Warning
} from "@mui/icons-material";
import {
	Box, Button, Dialog, Grid,
	IconButton, Input, InputLabel,
	Select, Stack, styled,
	TableCell, tableCellClasses, TableContainer,
	TableRow, Tooltip, tooltipClasses,
	Typography
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const CoAppMultiDropdownMenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const CoAppH2 = styled(Typography)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "1.3rem",
	fontWeight: "600",
	display: "inline"
}));

export const CoAppH4 = styled(Typography)(() => ({
	margin: "0",
	textAlign: "center",
	fontWeight: "400",
	fontSize: "1.25rem",
	lineHeight: "1.334",
	letterSpacing: "0em",
	padding: "5px",
	color: "rgba(0, 0, 0, 0.6)"
}));

export const CoAppH5 = styled(Typography)(() => ({
	margin: "0",
	textAlign: "center",
	fontWeight: "400",
	fontSize: ".75rem",
	lineHeight: "1.334",
	letterSpacing: "0em",
	padding: "5px",
	color: "rgba(0, 0, 0, 0.6)"
}));

export const CoAppDataGrid = styled(DataGridPro)(({ theme, isscim = "false" }) => ({
	backgroundColor: "#FFFFFF",
	borderBottomLeftRadius: isscim === "false" ? "20.12px" : "0px",
	borderBottomRightRadius: isscim === "false" ? "20.12px" : "0px",
	flexGrow: 1,
	border: "none",
	"& .MuiDataGrid-cell:focus-within": { //removed MUI focus border when a cell is focused
		outline: "none"
	},
	"& .MuiDataGrid-columnHeader:focus-within": {
		outline: "none"
	},
	"& .MuiDataGrid-columnHeader": {
		borderBottom: isscim === "true" ? "3px solid #0000001F" : "none"
	},
	"& .MuiDataGrid-row.Mui-hovered": {
		backgroundColor: theme.palette.primary._states.hover
	},
	"& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
		outline: "none",
	},
	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
		outline: "none"
	},
	"& .MuiDataGrid-row.CustomKillSwitchRow--active.Mui-selected": {
		backgroundColor: theme.palette.error._states.killSwitchSelected
	},
	"& .MuiDataGrid-row.CustomKillSwitchRow--active.Mui-hovered": {
		backgroundColor: theme.palette.error._states.killSwitchHover
	},
	"& .MuiDataGrid-row.CustomKillSwitchRow--active": {
		backgroundColor: theme.palette.error._states.killSwitchActiveBase
	},
	"& .MuiDataGrid-row.location-w-trigger": {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrast,
		"&:hover": {
			backgroundColor: theme.palette.primary.light
		},
		"&.Mui-selected": {
			backgroundColor: theme.palette.primary._states.selected,
			color: theme.palette.primary.dark
		},
	},
	"& .MuiDataGrid-root--pinnedBackground": {
		backgroundColor: "red"
	},
	".MuiDataGrid-cell--withRightBorder": {
		borderRight: "none"
	},
	".MuiDataGrid-columnHeader--withRightBorder": {
		borderRight: "none"
	},
	".MuiDataGrid-filler--pinnedLeft": {
		borderRight: "none"
	},
	"& ::-webkit-scrollbar": {
		backgroundColor: "#D7EBE5",
		width: "15px",
		borderRadius: isscim === "true" ? "0px" : "0px 0px 14px 0px",
	},
	"& ::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	},
}));

export const CoAppDataGridTextCellContainer = styled("div")(() => ({
	marginTop: "15px"
}));


export const CoAppDataGridToolbarButton = styled(Button)(() => ({
	backgroundColor: "#FFFFFF",
	color: "#2FBC70",
	textDecoration: "underline",
	margin: "0px 10px 0px 0px",
	float: "right",
	borderRadius: "10px",
	padding: "5px",
	":hover": {
		backgroundColor: "#E3E6EC",
	},
}));

export const CoAppGroupList = styled("div")(({ theme, overflowing, count, superadmin }) => {
	if (overflowing) {
		return {
			fontSize: theme.typography.body2.fontSize,
			textWrap: "wrap",
			height: "50px",
			alignContent: "center",
			"&::after": {
				content: `" +${count}"`,
				color: theme.palette.info.main
			},
			lineHeight: "16px",
			color: superadmin === "true" ? theme.palette.text.disabled : theme.palette.text.primary
		};
	} else {
		return {
			fontSize: theme.typography.body2.fontSize,
			textWrap: "wrap",
			height: "50px",
			alignContent: "center",
			lineHeight: "16px",
			color: superadmin === "true" ? theme.palette.text.disabled : theme.palette.text.primary
		};
	}
});

export const CoAppSelect = styled(Select)(() => ({
	"&.MuiOutlinedInput-root": {
		"& fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&:hover fieldset": {
			border: "1px solid #A9A9A9"
		},
		"&.Mui-focused fieldset": {
			border: "1px solid #A9A9A9"
		}
	},
	backgroundColor: "#FFFFFF",
	width: "250px",
	height: "50px"
}));

export const CoAppStack = styled(Stack)(() => ({
	margin: "auto",
	width: "95%"
}));

export const CoAppStackItem = styled("div")(() => ({
	borderRadius: "5px",
	backgroundColor: "#FFFFFF",
	boxShadow: "0px 2px 2px 2px #00000026",
	width: "auto",
	justifyContent: "flex-start",
	display: "flex",
	fontSize: "1rem",
	alignItems: "center",
	height: "69px",
	rowGap: "50px",
}));

export const CoAppInputLabel = styled(InputLabel)(() => ({
	"&.MuiInputLabel-root": {
		color: "#A9A9A9"
	}
}));

export const CoAppInlineInput = styled(Input)(() => ({
	color: "#A9A9A9",
	fontSize: "1.5rem",
	fontWeight: "600",
	marginRight: "50px",
}));

export const CoAppInlineInputHeader = styled(Typography)(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "2.3rem",
	fontWeight: "600",
	"&:hover": {
		cursor: "pointer",
		color: "#7482A0"
	}
}));

export const CoAppMainContainer = styled("div")(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	textAlign: "left",
	color: theme.palette.text.primary,
	border: 0,
	fontSize: "2.5rem",
	p: 2,
	margin: "auto",
	flexGrow: 1,
}));

export const CoAppCancelButton = styled(Button)(() => ({
	color: "#7482A0",
	backgroundColor: "#E3E6EC",
	textTransform: "capitalize",
	margin: "10px",
	height: "40px",
	fontWeight: "500",
	borderRadius: "10px",
	padding: "5px",
	border: "1px solid white",
	"&:hover": {
		backgroundColor: "white",
		color: "black",
		border: "1px solid #7482A0",
		fontWeight: "400"
	}
}));

export const CoAppConfirmStackItemGroup = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	margin: "auto",
	borderRadius: "5px",
}));

export const CoAppConfirmStackItemGroupTitle = styled("div")((props) => ({
	fontSize: "1.5rem",
	fontWeight: "bold",
	marginBottom: "20px",
	marginTop: props.isfirst ? "0px" : "30px"
}));

export const CoAppConfirmContainer = styled("div")(() => ({
	display: "block",
	justifyContent: "center",
	alignItems: "center",
	width: "100%"
}));

export const CoAppConfirmStack = styled(Stack)(() => ({
	margin: "auto",
	borderRadius: "5px",
	boxShadow: "0px 2px 2px 2px #00000026",
	width: "100%",
	border: "1px solid #00000026",
}));

export const CoAppConfirmStackItemContent = styled("p")((props) => ({
	width: !props.hasvalue ? "700px" : "200px",
	textOverflow: !props.hasvalue ? "none" : "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden"
}));

export const CoAppConfirmStackItem = styled("div")(({ itemtype }) => ({
	borderRadius: itemtype === "header" ? "5px 5px 0px 0px" : itemtype === "footer" ? "0px 0px 5px 5px" : "0px",
	backgroundColor: itemtype === "header" ? "#F7F8F9" : "#FFFFFF",
	width: "auto",
	position: "relative",
	display: "flex",
	fontSize: "1rem",
	alignItems: "center",
	height: "50px",
	borderBottom: "1px solid #00000026",
	paddingLeft: "15px",
	color: ["header", "footer"].includes(itemtype) ? "#000000" : "#A2A2A2",
}));

export const CoAppSettingsBackButton = styled(Button)(() => ({
	color: "#7482A0",
	textTransform: "none",
	width: "125px",
	height: "40px",
	"&:hover": {
		backgroundColor: "#EAF8F1",
		borderRadius: "10px"
	}
}));

export const CoAppBasicContainer = styled("div")(() => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}));

export const CoAppLoginContainer = styled("div")(({ theme }) => ({
	background: "linear-gradient(#01D5AB, #0072AE)",
	height: "100vh"
}));

export const CoAppLoginFlowContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100vh",
	width: "492px",
	margin: "auto"
}));

export const CoAppCloseIcon = styled(Close)(({ theme, top, left }) => ({
	position: "absolute",
	top: top ? top : 8,
	left: left ? left : 571,
	color: theme.palette.text.secondary,
	cursor: "pointer"
}));

export const CoAppTextButton = styled(Button)(({ theme, ismodal = "true", isrow = "false" }) => ({
	color: theme.palette.primary.main,
	textTransform: "none",
	fontSize: "14px",
	fontWeight: ismodal === "true" ? "600" : "400",
	":hover": {
		color: theme.palette.primary.dark,
		backgroundColor: isrow === "true" ? theme.palette.background.tableRow : theme.palette.background.default
	},
	marginRight: "12px"
}));

export const CoAppTextDestructiveButton = styled(Button)(({ theme }) => ({
	color: theme.palette.error.main,
	textTransform: "none",
	fontSize: "0.9rem",
	fontWeight: "400",
	":hover": {
		color: theme.palette.error.dark,
		backgroundColor: theme.palette.background.default
	},
	marginRight: "12px"
}));

export const CoAppCancelTextButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.secondary,
	textTransform: "none",
	fontSize: "14px",
	fontWeight: "600",
	":hover": {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.default
	},
	marginRight: "12px"
}));

export const CoAppDestructiveActionButton = styled(Button)(({ theme }) => ({
	color: theme.palette.primary.contrast,
	backgroundColor: theme.palette.error.main,
	textTransform: "none",
	fontSize: "14px",
	fontWeight: "600",
	":hover": {
		backgroundColor: theme.palette.error.dark
	},
	borderRadius: "20px",
	padding: "6px 16px",
	boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)"
}));

export const CoAppActionButton = styled(Button)(({ theme }) => ({
	color: theme.palette.primary.contrast,
	backgroundColor: theme.palette.primary.main,
	textTransform: "none",
	fontSize: "14px",
	fontWeight: "600",
	":hover": {
		backgroundColor: theme.palette.primary.dark
	},
	borderRadius: "20px",
	boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",

	"&.Mui-disabled": {
		color: theme.palette.primary.contrast,
		backgroundColor: theme.palette.action.disabled,
		":hover": {
			backgroundColor: theme.palette.action.disabled
		}
	}
}));

export const CoAppInvertedActionButton = styled(Button)(({ theme }) => ({
	color: theme.palette.primary.main,
	backgroundColor: theme.palette.primary.contrast,
	border: `1px solid ${theme.palette.primary.main}`,
	textTransform: "none",
	fontSize: "14px",
	fontWeight: "600",
	lineHeight: "20px",
	":hover": {
		color: theme.palette.primary.dark
	},
	borderRadius: "20px"
}));

export const CoAppLightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} arrow placement={props.placement ? props.placement : "bottom"} />
))(({ theme, opacity, placement }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: opacity === "true" ? "#FDFDFDE5" : "#FDFDFDF2",
		paddingTop: "4px",
		paddingBottom: "4px",
		color: theme.palette.text.primary,
		boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.33)",
		lineHeight: "20px"
	},
	[`& .${tooltipClasses.arrow}`]: {
		"&::before": {
			backgroundColor: "#FDFDFDF2",
			boxShadow: placement === "bottom" ? "2px 2px 2px 2px rgba(0, 0, 0, 0.33)" : "2px 0px 2px 0px rgba(0, 0, 0, 0.33)"
		}
	}
}));

export const CoAppDialog = styled(Dialog)(({ theme }) => ({
	padding: "8px"
}));

export const CoAppDialogTooltipContainer = styled("div")(() => ({
	display: "block",
	padding: "4px"
}));

export const CoAppDialogTooltipText = styled("div")(({ theme, name }) => ({
	color: name === "true" ? theme.palette.text.primary : theme.palette.text.secondary,
	fontSize: theme.typography.body3.fontSize,
	marginBottom: "-10px",
	marginTop: "-10px"
}));

export const CoAppWarningIcon = styled(Warning)(({ theme, withoutscroll }) => ({
	color: theme.palette.error.main,
	marginRight: "-15px",
	marginLeft: "20px",
	marginBottom: withoutscroll === "true" ? "0px" : "32px"
}));

export const CoAppDialogTitleIconAndTextContainer = styled("div")(() => ({
	display: "inline-flex",
	alignItems: "center"
}));

export const CoAppBasicTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.body}`]: {
		fontSize: theme.typography.body2.fontSize
	},
	[theme.breakpoints.down(1900)]: {
		paddingTop: "12px",
		paddingBottom: "12px"
	}
}));

export const CoAppBasicTableRow = styled(TableRow)(({ theme, withoutscroll }) => ({
	backgroundColor: theme.palette.background.tableRow,
	"&:last-child td, &:last-child th": {
		border: withoutscroll === "true" ? "" : 0,
	}
}));

export const CoAppBasicTableContainer = styled(TableContainer)(({ theme, withoutscroll }) => ({
	[theme.breakpoints.down(1900)]: {
		maxHeight: "450px"
	},
	maxHeight: "500px",
	borderRadius: withoutscroll === "true" ? "0px" : "0px 0px 8px 8px",
	overflowX: "hidden",
	overflowY: "auto",
	"&::-webkit-scrollbar": {
		backgroundColor: "#D7EBE5",
		width: "15px",
		borderRadius: "0px 0px 14px 0px"
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	}
}));

export const CoAppProfileAvatar = styled("div")(({ theme, h, w, fs = "" }) => ({
	color: theme.palette.text.primary,
	borderRadius: "50%",
	backgroundColor: "#AEEDDB",
	width: w ? w : "40px",
	textAlign: "center",
	lineHeight: h ? h : "40px",
	fontSize: fs ? fs : "16px",
	fontWeight: 500,
	height: h ? h : "40px",
	verticalAlign: "middle"
}));

export const CoAppProfileAvatarContainer = styled("div")(() => ({
	display: "flex",
	margin: "auto",
	float: "right",
	marginRight: "65px",
	marginTop: "20px",
	alignItems: "center",
	cursor: "pointer"
}));

export const CoAppProfileLogoutTextButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.secondary,
	width: "100px",
	":hover": {
		color: theme.palette.text.primary,
		backgroundColor: "transparent"
	},
	padding: "15px 5px 15px 0px",
	textTransform: "none",
	fontWeight: theme.typography.menuItem.fontWeight,
	fontSize: theme.typography.menuItem.fontSize
}));

export const CoAppExpandMoreIcon = styled(ExpandMore)(({ theme, clickedopen, usefloat = null }) => ({
	color: theme.palette.text.secondary,
	float: usefloat ? "right" : "none",
	cursor: "pointer",
	transform: clickedopen === "true" ? "rotateX(180deg)" : "rotateX(0deg)",
	transition: "transform 500ms ease",
}));

export const CoAppEditIcon = styled(Edit)(({ theme }) => ({
	color: theme.palette.primary.main,
	position: "absolute",
	right: 15,
	top: 20,
	":hover": {
		cursor: "pointer",
		color: theme.palette.primary.dark
	}
}));

export const CoAppProfileListText = styled(Typography)(({ theme, remaindercount }) => ({
	"&::after": {
		content: remaindercount ? `" +${remaindercount}"` : null,
		color: theme.palette.info.main
	}
}));

export const CoAppProfileResourceContainer = styled("div")(({ backgroundcolor }) => ({
	backgroundColor: backgroundcolor,
	color: "black",
	borderRadius: "14px",
	padding: "8px",
	maxHeight: "620.555px",
	overflowY: "auto",
	"&::-webkit-scrollbar": {
		backgroundColor: "#D7EBE5",
		width: "15px",
		borderRadius: "0px 14px 14px 0px"
	},
	"&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
		borderRadius: "8px",
		backgroundColor: "#BDBDBD",
		width: "7px",
		border: "4px solid transparent",
		backgroundClip: "content-box"
	}
}));

export const CoAppProfileContentContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.default,
	borderRadius: "4px",
	position: "absolute",
	width: "360px",
	top: 65,
	right: 70,
	zIndex: 999,
	boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
}));

export const CoAppProfileNameText = styled("div")(({ theme }) => ({
	paddingTop: "5px",
	fontSize: "16px",
	fontWeight: 400,
	color: theme.palette.text.primary,
	lineHeight: "24px",
	fontStyle: "normal",
	letterSpacing: "0.15px"
}));

export const CoAppProfileOrgNameText = styled("div")(({ theme }) => ({
	paddingTop: "5px",
	fontSize: "14px",
	fontWeight: 600,
	color: "#01D5AB",
	lineHeight: "24px",
	fontStyle: "normal",
	letterSpacing: "0.15px"
}));

export const CoAppProfileEmailText = styled("div")(({ theme }) => ({
	marginTop: "10px",
	fontSize: "14px",
	fontWeight: 400,
	color: theme.palette.text.primary,
	lineHeight: "24px",
	fontStyle: "normal",
	letterSpacing: "0.17px",
	marginLeft: "18px",
	marginBottom: "8px"
}));

export const CoAppNameCell = styled("div")(() => ({
	display: "flex",
	alignItems: "flex-start",
	flexWrap: "wrap",
	width: "100px",
}));

export const CoAppRuleWizardMain = styled("div")(({ theme }) => ({
	borderRadius: "20.12px",
	backgroundColor: "#FFFFFF",
	width: "1565px",
	[theme.breakpoints.up(1900)]: {
		minHeight: "88%",
		width: "1565px",
	},
	[theme.breakpoints.between(1600, 1900)]: {
		minHeight: "88%",
		width: "1440px",
	},
	[theme.breakpoints.between(1500, 1600)]: {
		minHeight: "85.5%",
		width: "1155px",
	},
	[theme.breakpoints.between(1000, 1500)]: {
		minHeight: "85%",
		width: "1160px"
	},
	[theme.breakpoints.down(1400)]: {
		minHeight: "85.5%",
		width: "940px"
	},
	position: "absolute",
	top: 100,
	left: 338,
	boxShadow: "0px 1.974px 12px -0.987px rgba(0, 0, 0, 0.13)"
}));

export const CoAppUserProfileMain = styled("div")(({ theme }) => ({
	borderRadius: "20.12px",
	backgroundColor: "#FFFFFF",
	width: "1565px",
	[theme.breakpoints.up(1900)]: {
		minHeight: "40%",
		width: "1565px",
	},
	[theme.breakpoints.between(1600, 1900)]: {
		minHeight: "40%",
		width: "1440px",
	},
	[theme.breakpoints.between(1500, 1600)]: {
		minHeight: "40%",
		width: "1155px",
	},
	[theme.breakpoints.between(1000, 1500)]: {
		minHeight: "40%",
		width: "1160px"
	},
	[theme.breakpoints.down(1400)]: {
		minHeight: "40%",
		width: "940px"
	},
	position: "absolute",
	top: 100,
	left: 338,
	boxShadow: "0px 1.974px 12px -0.987px rgba(0, 0, 0, 0.13)"
}));

export const CoAppOrganizationSettingsMain = styled("div")(({ theme }) => ({
	borderRadius: "20.12px",
	backgroundColor: "#FFFFFF",
	width: "1565px",
	[theme.breakpoints.up(1900)]: {
		minHeight: "60%",
		width: "1565px",
	},
	[theme.breakpoints.between(1600, 1900)]: {
		minHeight: "60%",
		width: "1440px",
	},
	[theme.breakpoints.between(1500, 1600)]: {
		minHeight: "60%",
		width: "1155px",
	},
	[theme.breakpoints.between(1000, 1500)]: {
		minHeight: "60%",
		width: "1160px"
	},
	[theme.breakpoints.down(1400)]: {
		minHeight: "60%",
		width: "940px"
	},
	position: "absolute",
	top: 100,
	left: 338,
	boxShadow: "0px 1.974px 12px -0.987px rgba(0, 0, 0, 0.13)"
}));

/**
 * width is calculated based on the positioning of the CoAppMain component absolutely (left: 338px) + 20px for padding on right
 * height is calculated based on the positioning of the CoAppMain component absolutely (top: 100px) + 20px for padding on bottom
 */
export const CoAppDataGridMain = styled("div")(({ theme }) => ({
	borderRadius: "20.12px",
	backgroundColor: "#FFFFFF",
	width: "calc(100% - 358px)",
	height: "calc(100% - 120px)",
	position: "absolute",
	top: 100,
	left: 338,
	boxShadow: "0px 1.974px 12px -0.987px rgba(0, 0, 0, 0.13)"
}));

export const CoAppDataGridContainer = styled("div")(({ theme, height = "620px" }) => ({
	height: height,
}));

export const CoAppDataGridToolbar = styled(Grid)(() => ({
	position: "relative",
	marginTop: "10px"
}));

export const CoAppDataGridPaginationContainer = styled(Grid)(() => ({
	position: "absolute",
	right: 5
}));

export const CoAppDataGridAllSelectedContainer = styled("div")(({ theme }) => ({
	display: "flex",
	alignContent: "center",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: theme.palette.background.tableRow,
	width: "100%",
	height: "56px",
	marginTop: "10px",
	gap: "5px"
}));

export const CoAppDataGridActionsContainer = styled(IconButton)(({ theme }) => ({
	":hover": {
		backgroundColor: theme.palette.primary._states.hover
	}
}));

export const CoAppDataGridHeaderTitle = styled(Typography)(({ theme }) => ({
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	[theme.breakpoints.between(1600, 2000)]: {
		maxWidth: "700px"
	},
	[theme.breakpoints.between(1500, 1600)]: {
		maxWidth: "600px"
	},
	[theme.breakpoints.between(1000, 1400)]: {
		maxWidth: "400px"
	},
}));

export const CoAppSuperAdminTextCell = styled("div")(({ theme, after = "false" }) => ({
	fontSize: theme.typography.body2.fontSize,
	height: "50px",
	alignContent: "center",
	lineHeight: "16px",
	"&::after": {
		content: "' CoApp Support'",
		letterSpacing: "0.17px",
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.body2.bold.fontWeight,
		fontSize: theme.typography.body2.fontSize
	},
	marginBottom: "-15px",
	marginTop: "-12px"
}));

export const CoAppCopyBlockContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.black_states.focus,
	width: "fit-content",
	padding: "10px",
	borderRadius: "4px"
}));

export const CoAppCopyBlockTypography = styled(Typography)(({ theme }) => ({
	paddingRight: "10px",
	maxWidth: "750px",
	overflowWrap: "break-word"
}));

export const CoAppHelpIcon = styled(Help)(({ theme }) => ({
	color: theme.palette.info.main,
	cursor: "pointer"
}));

export const CoAppPlayArrow = styled(PlayArrow)(({ theme, top, left }) => ({
	position: "absolute",
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.white_states.main,
	width: "60px",
	height: "60px",
	top: top ? top : 48,
	left: left ? left : 122,
	borderRadius: "50%",
	cursor: "pointer"
}));

export const CoAppThumbnailTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} arrow placement={props.placement ? props.placement : "bottom"} />
))(({ theme, opacity, placement }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: opacity === "true" ? "#FDFDFDE5" : "#FDFDFDF2",
		paddingTop: "4px",
		paddingBottom: "4px",
		maxWidth: "400px",
		border: `2px solid ${theme.palette.primary.main}`,
	},
	[`& .${tooltipClasses.arrow}`]: {
		"&::before": {
			backgroundColor: "#FDFDFDF2",
			boxShadow: placement === "bottom" ? "2px 2px 2px 2px rgba(0, 0, 0, 0.33)" : "2px 0px 2px 2px rgba(0, 0, 0, 0.33)"
		}
	}
}));

export const CoAppEmbeddedVideoContainer = styled("div")(({ theme }) => ({
	position: "relative",
	height: "700px"
}));

export const CoAppEmbeddedVideoLoadingContainer = styled("div")(({ theme }) => ({
	position: "absolute",
	top: 350,
	left: 900
}));

export const CoAppEmbeddedVideoIframe = styled("iframe")(({ theme }) => ({
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%"
}));

export const CoAppThumbnailOverlayText = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
	position: "absolute",
	top: 0,
	left: 50,
	width: "225px",
	textAlign: "center"
}));