import { Typography } from "@mui/material";

import CoAppBulkActivateIcon from "../../global/components/icons/CoAppBulkActivateIcon";
import CoAppBulkDeactivateIcon from "../../global/components/icons/CoAppBulkDeactivateIcon";
import { CoAppDataGridAllSelectedContainer, CoAppLightTooltip, CoAppTextButton } from "../../global/styled/global.styled";

export default function WorkstationDataGridSelectionRow(props) {
	const {
		shouldShow, selectedItems, workstations = [],
		bulkActivateHandler, bulkDeactivateHandler, onClearClickHandler,
		activateIsDisabled, deactivateIsDisabled
	} = props;

	const getDeactivateTooltip = () => {
		const count = workstations.filter((workstation) => {
			return selectedItems.includes(workstation.uuid) && workstation.killSwitchActive;
		}).length;

		return count === 0 ? "No workstation kill switches to deactivate" : `Deactivate killswitch on selected workstations (${count})`;
	};

	const getActivateTooltip = () => {
		const count = workstations.filter((workstation) => {
			return selectedItems.includes(workstation.uuid) && !workstation.killSwitchActive;
		}).length;
		return count === 0 ? "No workstation kill switches to activate" : `Activate killswitch on selected workstations (${count})`;
	};

	const getSelectionText = () => {
		return `${selectedItems.length} workstations selected`;
	};

	return (
		<>
			{
				shouldShow
					?
					(<CoAppDataGridAllSelectedContainer>
						<Typography variant="buttonSmall" color="text.coappPrimary" >
							{getSelectionText()}
						</Typography >
						<CoAppTextButton isrow="true" onClick={onClearClickHandler}>
							Clear selection
						</CoAppTextButton>
						<>
							<CoAppLightTooltip title={getActivateTooltip()}>
								<span>
									<CoAppBulkActivateIcon onClick={bulkActivateHandler} killswitch={"true"} disabled={activateIsDisabled} />
								</span>
							</CoAppLightTooltip>
							<CoAppLightTooltip title={getDeactivateTooltip()}>
								<span>
									<CoAppBulkDeactivateIcon onClick={bulkDeactivateHandler} disabled={deactivateIsDisabled} />
								</span>
							</CoAppLightTooltip>
						</>
					</CoAppDataGridAllSelectedContainer>)
					:
					null
			}
		</>
	);
}