import { useEffect } from "react";
import { Alert, Dialog, Step, StepButton, StepLabel, Stepper, Tooltip } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton } from "../../global/styled/global.styled";
import { WizardStepConnector, WizardStepperContainer } from "../styled/rule-wizard.styled";

import WizardStepIcon from "./WizardStepIcon";

export default function WizardStepper(props) {
	const activeStep = props.activeStep;
	const steps = props.steps;
	const stepsCompleted = props.stepsCompleted;
	const handleComplete = props.handleComplete;
	const handleStepButtonClick = props.handleStepButtonClick;
	const handleSubmit = props.handleSubmit;
	const handleBack = props.handleBack;
	const isSavingProgress = props.isSavingProgress;
	const wizardFormError = props.wizardFormError;
	const setWizardFormError = props.setWizardFormError;

	useEffect(() => {
	}, [activeStep, isSavingProgress]);

	return (
		<WizardStepperContainer direction="row">
			<Stepper connector={<WizardStepConnector />} alternativeLabel nonLinear activeStep={activeStep} sx={{ width: "50%" }}>
				{steps.map(step => (
					<Step completed={stepsCompleted[step.num - 1]} key={step.num}>
						<Tooltip title={step.desc} placement="top">
							<StepButton onClick={() => handleStepButtonClick(step.num)}>
								<StepLabel StepIconComponent={WizardStepIcon}>{step.desc}</StepLabel>
							</StepButton>
						</Tooltip>
					</Step>
				))}
			</Stepper>


			<CoAppCancelTextButton className="StepButton" onClick={handleBack} disabled={activeStep === 0}>
				Back
			</CoAppCancelTextButton>

			{activeStep === 4 ? (
				<CoAppActionButton className="StepButton" onClick={handleComplete}>
					Finish
				</CoAppActionButton>
			) : (
				<CoAppActionButton className="StepButton" onClick={handleSubmit}>
					Next
				</CoAppActionButton>
			)}
			<Dialog onClose={() => setWizardFormError("")} open={wizardFormError !== ""}>
				<Alert severity="error">{wizardFormError}</Alert>
			</Dialog>
		</WizardStepperContainer>
	);
}