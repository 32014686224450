import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import axios from "axios";

import apiRoutes from "../../../../../constants/api-routes";
import messageLevels from "../../../../../constants/message-levels";
import messages from "../../../../../constants/messages";
import useAuthHeader from "../../../../../hooks/useAuthHeader";
import useToastAlert from "../../../../../hooks/useToastAlert";
import { selectOrganizationId } from "../../../../../redux/userSlice";
import CoAppCreateGroupOrRoleModal from "../../../../global/components/modals/CoAppCreateGroupOrRoleModal";
import CoAppDestructiveConfirmationModal from "../../../../global/components/modals/CoAppDestructiveConfirmationModal";
import CoAppEditNameModal from "../../../../global/components/modals/CoAppEditNameModal";
import ThreeDotMenu from "../../../../global/components/ThreeDotMenu";
import { CoAppInvertedActionButton, CoAppLightTooltip } from "../../../../global/styled/global.styled";
import IdpGroupAutocompleteCell from "../IdpGroupAutocompleteCell";
import ScimWizardDataGrid from "../ScimWizardDataGrid";
import { ScimWizardDataGridStepContainer, ScimWizardRoleNameCell } from "../styled/scim-wizard.styled";

export default function Step5(props) {
	const {
		identityProviderName, mappedRoles, setMappedRoles,
		validateSuperAdminRoleIsMapped, roleMappingStatus
	} = props;

	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const organizationId = useSelector(selectOrganizationId);
	const apiRef = useGridApiRef();
	const [roles, setRoles] = useState([]);
	const [idpGroups, setIdpGroups] = useState([]);
	const [roleName, setRoleName] = useState("");
	const [roleNameError, setRoleNameError] = useState();
	const [createRoleDialogOpen, setCreateRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [roleBeingEdited, setRoleBeingEdited] = useState({});
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [roleBeingDeleted, setRoleBeingDeleted] = useState({});

	const columns = [
		{
			field: "name",
			headerName: "CoApp roles",
			sortable: true,
			flex: 1,
			renderCell: (params) => <ScimWizardRoleNameCell variant="body2" noWrap={true} superadmin={params.row.isSuperAdmin ? "true" : "false"}>{params.value}</ScimWizardRoleNameCell>
		},
		{
			field: "idpGroups",
			headerName: `${identityProviderName} groups`,
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<IdpGroupAutocompleteCell
					options={idpGroups}
					mapped={mappedRoles}
					setMapped={setMappedRoles}
					resourceName={params.row.name}
					apiRef={apiRef}
					isSuperAdmin={params.row.isSuperAdmin}
					validateSuperAdminRoleIsMapped={validateSuperAdminRoleIsMapped}
					roleMappingStatus={roleMappingStatus}
					initializeGroupOptions={initializeExternalGroups}
					setExternalGroups={setIdpGroups}
				/>
			)
		},
		{
			field: "actions",
			type: "actions",
			sortable: false,
			flex: .1,
			renderCell: (params) => (
				params.row.isSuperAdmin ?
					<CoAppLightTooltip title="Super Admin role cannot be edited" placement="right">
						<div>
							<ThreeDotMenu options={[]} isCoAppSuperAdmin={true} />
						</div>
					</CoAppLightTooltip>
					:
					<div>
						<ThreeDotMenu
							options={[
								{
									name: "Edit",
									optionClickHandler: () => {
										toggleEditRoleDialog(params.row);
									},
								},
								{
									name: "Delete",
									optionClickHandler: () => {
										toggleDeleteRoleDialog(params.row);
									},
								},
							]}
						/>
					</div>
			)
		}
	];

	const toggleCreateRoleDialog = () => {
		setCreateRoleDialogOpen(!createRoleDialogOpen);
		setRoleNameError("");
		setRoleName("");
	};

	const toggleEditRoleDialog = (role = {}) => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameError("");
		setRoleName("");
		setRoleBeingEdited(role);
	};

	const toggleDeleteRoleDialog = (role = {}) => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
		setRoleBeingDeleted(role);
	};

	const handleRoleNameChange = (e) => {
		setRoleName(e.target.value);
		setRoleNameError("");
	};

	const createNewRole = () => {
		if (!roleName) {
			setRoleNameError("Required *");
			return;
		}

		let role = { name: roleName };

		axios.post(apiRoutes.createRole, role, { headers: authHeader })
			.then(() => {
				handleToastAlert(messageLevels.SUCCESS, messages.ROLE_CREATED_SUCCESS_MSG);
				toggleCreateRoleDialog();
				initializeOrganizationRoles();
			})
			.catch((err) => {
				if (err.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleCreateRoleDialog();
				handleToastAlert(messageLevels.ERROR, messages.ROLE_CREATION_ERROR_MSG);
			});
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleName) return;

		if (roleName === roleBeingEdited.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleBeingEdited.id), { name: roleName }, { headers: authHeader })
			.then(() => {
				initializeOrganizationRoles();
				toggleEditRoleDialog();
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				handleToastAlert(messageLevels.ERROR, messages.ROLE_NAME_UPDATE_ERROR_MSG);
			});
	};
	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleBeingDeleted.id, { headers: authHeader })
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.ROLE_DELETION_SUCCESS_MSG(false));
				initializeOrganizationRoles();
				toggleDeleteRoleDialog();
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.ROLE_DELETION_ERROR_MSG);
				toggleDeleteRoleDialog();
			});
	};

	const initializeOrganizationRoles = () => {
		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then((res) => {
				let superAdminRole = res.data.find(role => role.isSuperAdmin);
				let superAdminIndex = res.data.indexOf(superAdminRole);
				res.data.splice(superAdminIndex, 1);
				res.data.unshift(superAdminRole);
				setRoles(res.data);
				let tempMappedRoles = { ...mappedRoles };
				for (let role of res.data) {
					if (Object.keys(roleBeingEdited).length > 0 && role.id === roleBeingEdited.id) {
						tempMappedRoles[`${role.name}`] = tempMappedRoles[`${roleBeingEdited.name}`];
					} else {
						tempMappedRoles[`${role.name}`] = !tempMappedRoles[`${role.name}`] ? [] : tempMappedRoles[`${role.name}`];
					}
				}
				if (Object.keys(roleBeingDeleted).length > 0) {
					delete tempMappedRoles[`${roleBeingDeleted.name}`];
				}
				if (Object.keys(roleBeingEdited).length > 0) {
					delete tempMappedRoles[`${roleBeingEdited.name}`];
				}
				setMappedRoles(tempMappedRoles);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const initializeExternalGroups = async () => {
		axios.get(apiRoutes.getExternalGroups(organizationId), {
			headers: authHeader
		})
			.then((res) => {
				setIdpGroups(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		initializeOrganizationRoles();
	}, []);

	return (
		<ScimWizardDataGridStepContainer>
			<Stack direction="row" spacing={10} sx={{ paddingLeft: "55px" }}>
				<Typography variant="h3_5">Map Roles</Typography>
				<CoAppInvertedActionButton onClick={toggleCreateRoleDialog}>
					Add Role
				</CoAppInvertedActionButton>
			</Stack>
			<ScimWizardDataGrid
				apiRef={apiRef}
				rows={roles}
				columns={columns}
			/>
			<CoAppCreateGroupOrRoleModal
				cancelClickHandler={toggleCreateRoleDialog}
				changeHandler={handleRoleNameChange}
				dialogOpen={createRoleDialogOpen}
				nameError={roleNameError}
				confirmClickHandler={createNewRole}
				isRole={true}
			/>
			<CoAppEditNameModal
				dialogOpen={editRoleDialogOpen}
				dialogTitle="Edit role name"
				changeHandler={handleRoleNameChange}
				placeholderText={roleBeingEdited.name}
				confirmClickHandler={handleRoleNameChangeSubmit}
				cancelClickHandler={toggleEditRoleDialog}
				actionText="Save"
				editNameError={roleNameError}
				setEditNameError={setRoleNameError}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteRoleDialogOpen}
				dialogTitle={`Delete ${roleBeingDeleted.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(roleBeingDeleted.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={toggleDeleteRoleDialog}
				actionText="Delete"
			/>
		</ScimWizardDataGridStepContainer>
	);
}