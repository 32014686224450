import { useState } from "react";
import { Stack, Typography } from "@mui/material";

import CoAppEmbeddedVideo from "../../../../global/components/CoAppEmbeddedVideo";
import CoAppCopyBlock from "../../../../global/components/inputs/CoAppCopyBlock";
import ssoScimVideoLinks from "../ssoScimVideoLinks";
import { ScimWizardStepContainer } from "../styled/scim-wizard.styled";

export default function Step2(props) {
	const { entityId, acsUrl, identityProviderName } = props;

	const [showAppCreationVideo, setShowAppCreationVideo] = useState(false);
	const [showAppCreationThumbnail, setShowAppCreationThumbnail] = useState(false);
	const [showSamlSetupVideo, setShowSamlSetupVideo] = useState(false);
	const [showSamlSetupThumbnail, setShowSamlSetupThumbnail] = useState(false);
	const [showAssignGroupsUsersVideo, setShowAssignGroupsUsersVideo] = useState(false);
	const [showAssignGroupsUsersThumbnail, setShowAssignGroupsUsersThumbnail] = useState(false);

	return (
		<ScimWizardStepContainer spacing={4}>
			<Typography variant="h3_5">Configure SSO</Typography>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1">{`1. Create an enterprise application for CoApp within ${identityProviderName}.`}</Typography>
				<CoAppEmbeddedVideo
					showVideo={showAppCreationVideo}
					setShowVideo={setShowAppCreationVideo}
					showThumbnail={showAppCreationThumbnail}
					setShowThumbnail={setShowAppCreationThumbnail}
					iframeTitle="Create Enterprise App Video"
					iframeSrc={ssoScimVideoLinks.createSamlAppVideo}
					thumbnailSrc={ssoScimVideoLinks.createSamlAppThumbnail}
					thumbnailAltText="Create Enterprise App Video Thumbnail"
				/>
			</Stack>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1">{`2. Set up single sign-on with SAML and enter the following values into ${identityProviderName}.`}</Typography>
				<CoAppEmbeddedVideo
					showVideo={showSamlSetupVideo}
					setShowVideo={setShowSamlSetupVideo}
					showThumbnail={showSamlSetupThumbnail}
					setShowThumbnail={setShowSamlSetupThumbnail}
					iframeTitle="SAML App SSO Setup Video"
					iframeSrc={ssoScimVideoLinks.configureSamlAppVideo}
					thumbnailSrc={ssoScimVideoLinks.configureSamlAppThumbnail}
					thumbnailAltText="SAML App SSO Setup Video Thumbnail"
				/>
			</Stack>
			<CoAppCopyBlock
				labelText="Identifier (Entity ID)"
				textToBeCopied={entityId}
			/>
			<CoAppCopyBlock
				labelText="Reply URL (Assertion Consumer Service URL)"
				textToBeCopied={acsUrl}
			/>
			<Stack direction="row" spacing={1}>
				<Typography variant="body1">{`3. Assign groups and/or users to your enterprise app in ${identityProviderName}.`}</Typography>
				<CoAppEmbeddedVideo
					showVideo={showAssignGroupsUsersVideo}
					setShowVideo={setShowAssignGroupsUsersVideo}
					showThumbnail={showAssignGroupsUsersThumbnail}
					setShowThumbnail={setShowAssignGroupsUsersThumbnail}
					iframeTitle="Assign Groups and Users Video"
					iframeSrc={ssoScimVideoLinks.assignGroupsVideo}
					thumbnailSrc={ssoScimVideoLinks.assignGroupsThumbnail}
					thumbnailAltText="Assign Groups and Users Thumbnail"
				/>
			</Stack>
		</ScimWizardStepContainer>
	);

}