import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	CircularProgress, Container,
	Stack, Switch
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import { customNameComparator, getDataGridFakeData } from "../../../../helpers/coappDataGridUtils";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationSsoAndScim } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/CoAppDataGridDateTimeCell";
import CoAppDatagridHeader from "../../../global/components/datagrid/CoAppDatagridHeader";
import CoAppDataGridListCell from "../../../global/components/datagrid/CoAppDataGridListCell";
import CoAppDataGridNameCell from "../../../global/components/datagrid/CoAppDataGridNameCell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/CoAppDataGridSkeletonCell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/CoAppStandardDataGrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/CoAppAddItemsToListModal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/CoAppDestructiveConfirmationModal";
import CoAppEditNameModal from "../../../global/components/modals/CoAppEditNameModal";
import ThreeDotMenu from "../../../global/components/ThreeDotMenu";

const groupUserColumns = ["actions", "user", "roles", "groups", "status", "lastExtensionHeartbeat", "createdAt"];

export default function GroupUsers() {
	const dummyRows = getDataGridFakeData(groupUserColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { viewUserPage, updateCoAppNavigation } = useCoAppNavigation();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("groupUsers");
	const navigate = useNavigate();
	const { groupId } = useParams();
	const { pathname, state } = useLocation();
	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);

	const [addUsersToggled, setAddUsersToggled] = useState(false);
	const [availableUsers, setAvailableUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [groupUsers, setGroupUsers] = useState(dummyRows);
	const [userSeed, setUserSeed] = useState(1);
	const [usersToAdd, setUsersToAdd] = useState([]);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [removeUsersIsToggled, setRemoveUsersIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");
	const [loading, setLoading] = useState(true);
	const [optionsLoading, setOptionsLoading] = useState(true);

	const columns = [
		{
			editable: false,
			field: "user",
			flex: 1,
			headerName: "User",
			sortable: true,
			sortComparator: customNameComparator,
			valueGetter: (params) => params.value === "" ? "" : params,
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} isUser={true} />}
				/>

			),
		},
		{
			editable: false,
			field: "roles",
			flex: 1,
			headerName: "Roles",
			sortable: false,
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Roles" />}
				/>
			),
		},
		{
			disableColumnMenu: true,
			editable: false,
			field: "groups",
			filterable: false,
			sortable: false,
			flex: 1,
			headerName: "Groups",
			pinnable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Groups" />}
				/>
			),
		},
		{
			editable: false,
			field: "active",
			width: 90,
			headerName: "Status",
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={params.row.active}
							disabled={organizationSsoStatus}
							onChange={() => handleUserStatusToggle(params.row.id, params.row.active === true ? "false" : "true")}
						/>
					}
				/>
			),
		},
		{
			editable: false,
			field: "lastHeartbeat",
			width: 200,
			headerName: "Last heartbeat",
			pinnable: false,
			sortable: true,
			valueGetter: (params) => !params ? null : new Date(params),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.row.workstation?.lastPhoneHome} />}
				/>
			),
		},
		{
			editable: false,
			field: "createdAt",
			width: 200,
			headerName: "Created",
			pinnable: false,
			valueGetter: (params) => !params ? null : new Date(params),
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			),
		},
		{
			field: "actions",
			flex: .1,
			sortable: false,
			type: "actions",
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							{
								organizationSsoStatus ?
									<ThreeDotMenu
										options={[
											{
												name: "View User",
												optionClickHandler: () => {
													viewUserPage(params, navigationItemReferences.groupNavItemIndex, groupId, state.name);
												},
											},
										]}
									/>
									:
									<ThreeDotMenu
										options={[
											{
												name: "Remove From Group",
												optionClickHandler: () => {
													removeUserFromGroup(params.row.id);
												},
											},
											{
												name: "View User",
												optionClickHandler: () => {
													viewUserPage(params, navigationItemReferences.groupNavItemIndex, groupId, state.name);
												},
											},
										]}
									/>
							}
						</div>
					}
				/>
			),
		},
	];

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateCoAppNavigation(false, navigationItemReferences.groupNavItemIndex, groupNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				handleToastAlert(messageLevels.ERROR, messages.GROUP_NAME_UPDATE_ERROR_MSG);
			});
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then(() => {
			handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(false));
			updateCoAppNavigation(true, navigationItemReferences.groupNavItemIndex);
			navigate(pages.groupManagement, { replace: true });
			handleToggleDeleteGroupDialog();
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
			handleToggleDeleteGroupDialog();
		});
	};

	const addUsersToGroup = () => {
		const userIds = usersToAdd.map(u => u.id);
		let requestJson = {
			users: userIds,
			group: { id: groupId }
		};
		axios.post(apiRoutes.addUsersToGroup, requestJson, {
			headers: authHeader
		}).then(() => {
			toggleAddNewDialog();
			handleToastAlert(messageLevels.SUCCESS, messages.USERS_ADDED_SUCCESS_MSG);
		}).catch(err => {
			console.error(err);
			handleToastAlert(messageLevels.ERROR, messages.USERS_ADDED_ERROR_MSG);
		});
	};

	const addUserToUsersToAdd = (value) => {
		//value is an array of user names, we need to convert them to user objects to display name + email in tooltip and add/remove based on email OR name
		value = value.map((user) => {
			let tempArr = [...groupUsers, ...availableUsers];
			let foundUser = tempArr.find((row) => row.name === user);
			//set the name to the email when the user has no firstname and lastname
			foundUser.name = foundUser.name === foundUser.email ? foundUser.email : `${foundUser.firstname} ${foundUser.lastname}`;
			return foundUser;
		});
		setUsersToAdd(value);
	};

	const init = () => {
		let userQueryObject = {
			groupId: groupId,
		};
		axios
			.get(apiRoutes.getUsers, {
				headers: authHeader,
				params: userQueryObject,
			})
			.then((res) => {
				res.data.forEach((user) => {
					user["user"] = {
						firstname: user.firstname,
						lastname: user.lastname,
						email: user.email
					};
				});
				setGroupUsers(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const initUsersForAdd = () => {
		axios
			.get(apiRoutes.getUsers, {
				headers: authHeader,
			})
			.then((res) => {
				let users = res.data.filter((user) => {
					return !groupUsers.some((groupUser) => groupUser.id === user.id);
				});
				users = users.map((user) => {
					return {
						id: user.id,
						name: user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.email,
						email: user.email,
						firstname: user.firstname,
						lastname: user.lastname,
					};
				});
				setAvailableUsers(users);
				setOptionsLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleUserStatusToggle = (id, state) => {
		let userJSON = {
			active: state
		};
		axios.put(apiRoutes.setUserActive(id), userJSON, { headers: authHeader })
			.then(() => {
				init();
			})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, messages.USER_TOGGLE_ERROR_MSG);
			});
	};

	const removeUserFromGroup = (userId) => {
		axios
			.delete(apiRoutes.removeUserFromGroup(userId, groupId), {
				headers: authHeader,
			})
			.then(() => {
				init();
				handleToastAlert(messageLevels.INFO, messages.USER_REMOVED_SUCCESS_MSG(false));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.USER_REMOVED_ERROR_MSG);
			});
	};

	const bulkRemoveUsersFromGroup = () => {
		const deleteData = {
			userIds: selectedUsers
		};
		axios.delete(apiRoutes.bulkRemoveUsersFromGroup(groupId), { headers: authHeader, data: deleteData })
			.then(() => {
				init();
				handleToastAlert(messageLevels.INFO, messages.USER_REMOVED_SUCCESS_MSG(selectedUsers.length > 1));
				setSelectedUsers([]);
				setRemoveUsersIsToggled(false);
			})
			.catch(err => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.USER_REMOVED_ERROR_MSG);
			});
	};

	const toggleAddNewDialog = () => {
		if (!addUsersToggled) {
			initUsersForAdd();
			setAddUsersToggled(true);
		} else {
			setAddUsersToggled(false);
			setAvailableUsers([]);
			setUsersToAdd([]);
			setOptionsLoading(true);
			setUserSeed(userSeed + 1);
		}
	};

	useEffect(() => {
		init();
		handleInitializeSortModel();
	}, [userSeed, state.name]);

	if (groupId) {
		return (
			<>
				<CoAppDatagridHeader
					title={`${state.name} User Management`}
					subResourceType="User"
					resourceType="Group"
					addOnClickHandler={toggleAddNewDialog}
					addIsPresent={organizationSsoStatus ? false : true}
					editIsPresent={true}
					editOnClickHandler={handleToggleGroupEditDialog}
					deleteIsPresent={true}
					deleteOnClickHandler={handleToggleDeleteGroupDialog}
				/>
				<CoAppStandardDataGrid
					columns={columns}
					rows={groupUsers}
					pinnedColumns={
						organizationSsoStatus ?
							["actions", "name", "active"]
							:
							["actions", "__check__", "name", "active"]
					}
					allowSelection={organizationSsoStatus ? false : true}
					targetResource="users"
					parentResource="group"
					parentResourceName={state.name}
					bulkActionText="Remove"
					selectionModel={selectedUsers}
					setSelectionModel={setSelectedUsers}
					handleModalToggle={() => setRemoveUsersIsToggled(prev => !prev)}
					loading={loading}
					sortModel={sortModel}
					handleSortModelChange={handleSortModelChange}
				/>
				<CoAppAddItemsToListModal
					addButtonTitle="Save"
					inputLabel="User(s)"
					addItemsIsToggled={addUsersToggled}
					addItemsToCollection={addUsersToGroup}
					addItemsToNewArray={addUserToUsersToAdd}
					dialogTitle={`Add user(s) to ${state.name} group`}
					items={availableUsers}
					itemsToAdd={usersToAdd}
					toggleDialog={toggleAddNewDialog}
					loading={optionsLoading}
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={deleteGroupIsToggled}
					dialogTitle={`Delete ${state.name} group?`}
					dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
					confirmClickHandler={confirmGroupDeletion}
					cancelClickHandler={handleToggleDeleteGroupDialog}
					actionText="Delete"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={removeUsersIsToggled}
					dialogTitle={`Remove ${selectedUsers.length > 1 ? "users" : "user"} from ${state.name} group?`}
					dialogMessage={`Are you sure that you want to remove the selected ${selectedUsers.length > 1 ? "users" : "user"} from the ${state.name} group?`}
					confirmClickHandler={bulkRemoveUsersFromGroup}
					cancelClickHandler={() => setRemoveUsersIsToggled(false)}
					actionText="Remove"
				/>
				<CoAppEditNameModal
					dialogOpen={editGroupIsToggled}
					dialogTitle="Edit group name"
					changeHandler={handleGroupNameChange}
					placeholderText={state.name}
					confirmClickHandler={handleGroupNameChangeSubmit}
					cancelClickHandler={handleToggleGroupEditDialog}
					actionText="Save"
					editNameError={groupNameError}
					setEditNameError={setGroupNameError}
				/>
			</>
		);
	} else {
		return (
			<Container sx={{ maxWidth: "150px!important", marginTop: "50px" }}>
				<Stack spacing={2}>
					<CircularProgress sx={{ color: "#2FBD70" }} />
				</Stack>
			</Container>
		);
	}
}
