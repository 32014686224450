import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Divider, Skeleton, Stack, Switch,
	Typography
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectPermissions, selectUserId, setPermissions } from "../../../../redux/userSlice";
import CoAppDatagridHeader from "../../../global/components/datagrid/CoAppDatagridHeader";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/CoAppDestructiveConfirmationModal";
import CoAppEditNameModal from "../../../global/components/modals/CoAppEditNameModal";

import { PermissionGroupContainer, PermissionsContainer } from "./styled/role.styled";

const fakePermissions = Array.from({ length: 5 }, () => ({ id: Math.random(), category: "" }));

export default function RolePermissions() {
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { updateCoAppNavigation } = useCoAppNavigation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roleId } = useParams();
	const [containerHeight, setContainerHeight] = useState("715px");
	const containerRef = useRef(null);
	const { pathname, state } = useLocation();
	const currentUserPermissions = useSelector(selectPermissions);
	const currentUserId = useSelector(selectUserId);
	const [role, setRole] = useState({});
	const [rolePermissions, setRolePermissions] = useState([]);
	const [permissionSeed, setPermissionSeed] = useState("");
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [roleNameInput, setRoleNameInput] = useState("");
	const [roleNameError, setRoleNameError] = useState("");
	const [manageUsersWarningDialogOpen, setManageUsersWarningDialogOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	const init = () => {
		if (roleId) {
			axios.get(apiRoutes.getRoleById(roleId), { headers: authHeader })
				.then((response) => {
					setRole(response.data);
					axios.get(apiRoutes.getPermissions, { headers: authHeader })
						.then((res) => {
							res.data.forEach(permission => {
								response.data.permissions.forEach(rolePermission => {
									if (rolePermission.name === permission.name) {
										permission.active = true;
									}
								});
							});
							setRolePermissions(res.data);
							setLoading(false);
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const toggleDeleteRoleDialog = () => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then(() => {
			updateCoAppNavigation(true, navigationItemReferences.roleNavItemIndex);
			handleToastAlert(messageLevels.INFO, messages.ROLE_DELETION_SUCCESS_MSG(false));
			navigate(pages.roleManagement, { replace: true });
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.ROLE_DELETION_ERROR_MSG);
			toggleDeleteRoleDialog();
		});
	};

	const toggleEditRoleDialog = () => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameInput("");
		setRoleNameError("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === state.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleId), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				toggleEditRoleDialog();
				updateCoAppNavigation(false, navigationItemReferences.roleNavItemIndex, roleNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				handleToastAlert(messageLevels.ERROR, messages.ROLE_NAME_UPDATE_ERROR_MSG);
			});
	};

	const handleCurrentUserPermissionUpdate = () => {
		axios.get(apiRoutes.getUser(1, currentUserId, "", null), { headers: authHeader })
			.then(res => {
				dispatch(setPermissions(res.data.permissions));
			})
			.catch(err => console.log(err));
	};

	const togglePermission = (permission, bypassCheck = true) => {
		if (permission?.name?.includes("MANAGE_USERS") && permission?.active && !bypassCheck) {
			const countOfManageUsersPermissions = currentUserPermissions.filter(p => p.includes("MANAGE_USERS")).length;
			if (countOfManageUsersPermissions === 1) {
				setManageUsersWarningDialogOpen(true);
				return;
			}
		}
		//permission variable will be an empty object when user wants to proceed with manage users 
		//permission removal, so we need to find the permission object to pass to our services
		if (Object.keys(permission).length === 0) {
			//filter permissions to extract manage users permission
			const manageUsersPermission = rolePermissions.filter(p => p.name.includes("MANAGE_USERS"));
			permission = manageUsersPermission[0];
		}
		axios.put(apiRoutes.updateRolePermission(roleId, permission.id), { active: !permission.active }, { headers: authHeader })
			.then(() => {
				setPermissionSeed(Math.random());
				//TODO:: placeholder, update navigate to expected destination once determined
				if (permission.name.includes("MANAGE_USERS") && manageUsersWarningDialogOpen) {
					navigate(pages.home);
				}

				const currentUserIsInCurrentRolesUsers = role.users.filter(user => user.id === currentUserId).length > 0;
				if (currentUserIsInCurrentRolesUsers) {
					handleCurrentUserPermissionUpdate();
				}
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, permission.active ? messages.PERMISSION_REMOVED_ERROR_MSG : messages.PERMISSION_ADDED_ERROR_MSG);
			});
	};

	const handleContainerHeightChange = () => {
		if (containerRef.current) {
			const yPosition = containerRef.current.getBoundingClientRect().y;
			const windowHeight = window.innerHeight;
			const appliedPadding = 20;
			setContainerHeight(`${windowHeight - yPosition - appliedPadding}px`);
		} else {
			setContainerHeight("");
		}
	};

	useEffect(() => {
		init();
		handleContainerHeightChange();
		window.addEventListener("resize", handleContainerHeightChange);

		return () => window.removeEventListener("resize", handleContainerHeightChange);
	}, [permissionSeed, state.name, containerRef.current, containerHeight]);

	const groupedPermissions = {};
	rolePermissions.forEach(permission => {
		if (!groupedPermissions[permission.category]) {
			groupedPermissions[permission.category] = [];
		}
		groupedPermissions[permission.category].push(permission);
	});
	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Permissions Management`}
				resourceType="Role"
				subResourceType="Permissions"
				addIsPresent={false}
				editIsPresent={state.isSuperAdmin ? false : true}
				editOnClickHandler={toggleEditRoleDialog}
				deleteIsPresent={state.isSuperAdmin ? false : true}
				deleteOnClickHandler={toggleDeleteRoleDialog}
				hasDescription={true}
				description={`Manage permissions for ${state.name}.`}
			/>
			{
				loading ?
					<PermissionsContainer ref={containerRef} height={containerHeight}>
						{
							fakePermissions.map((fakePermission, index) => (
								<div key={fakePermission.id}>
									{index !== 0 && <Divider sx={{ width: "60%", margin: "25px 0px 0px 25px" }} />}
									<Skeleton variant="text" width={200} height={50} sx={{ marginLeft: "25px", marginTop: "20px", marginBottom: "20px" }} />
									{
										Array.from({ length: index % 2 === 0 ? 1 : 2 }, (_, i) => (
											<>
												<Stack direction="row" spacing={2} sx={{ marginLeft: "25px", marginBottom: "2px" }}>
													<Skeleton key={i} variant="rectangular" width={100} height={25} sx={{ marginLeft: "25px", borderRadius: "10px" }} />
													<Skeleton key={i} variant="rectangular" width={400} height={25} sx={{ marginLeft: "25px", borderRadius: "10px" }} />
												</Stack>
												<Skeleton variant="text" width={400} height={20} sx={{ marginLeft: "25px", marginBottom: "10px" }} />
											</>
										))
									}
								</div>
							))
						}
					</PermissionsContainer>
					:
					<PermissionsContainer ref={containerRef} height={containerHeight}>
						{Object.keys(groupedPermissions).map((category, categoryIndex) => (
							<div key={category}>
								{categoryIndex !== 0 && <Divider sx={{ width: "60%", margin: "25px 0px 0px 25px" }} />}
								<Typography sx={{ margin: "25px" }} variant="h3">{category}</Typography>
								{groupedPermissions[category].map((permission, index) => (

									<PermissionGroupContainer key={index}>
										<Typography variant="h3" sx={{ display: "inline" }}>
											<Switch sx={{ marginRight: "5px" }} checked={permission.active} onChange={() => togglePermission(permission, false)} />
											{permission.friendlyName}
										</Typography>
										<Typography sx={{ marginLeft: "15px", paddingBottom: categoryIndex === Object.keys(groupedPermissions).length - 1 ? "20px" : "0px" }} variant="body2">{permission.description}</Typography>
									</PermissionGroupContainer>
								))}
							</div>
						))}
					</PermissionsContainer>
			}
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteRoleDialogOpen}
				dialogTitle={`Delete ${state.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={toggleDeleteRoleDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={manageUsersWarningDialogOpen}
				dialogTitle="Warning: You will no longer be able to manage permissions"
				dialogMessage={messages.REMOVE_MANAGE_USERS_ROLE_MSG(state.name)}
				confirmClickHandler={() => togglePermission({})}
				cancelClickHandler={() => setManageUsersWarningDialogOpen(false)}
				actionText="Proceed"
			/>
			<CoAppEditNameModal
				dialogOpen={editRoleDialogOpen}
				dialogTitle="Edit role name"
				changeHandler={handleRoleNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleRoleNameChangeSubmit}
				cancelClickHandler={toggleEditRoleDialog}
				actionText="Save"
				editNameError={roleNameError}
				setEditNameError={setRoleNameError}
			/>
		</>
	);
}