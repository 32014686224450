import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import { getDataGridFakeData } from "../../../../helpers/coappDataGridUtils";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationId } from "../../../../redux/userSlice";
import CoAppDatagridHeader from "../../../global/components/datagrid/CoAppDatagridHeader";
import CoAppDataGridNameCell from "../../../global/components/datagrid/CoAppDataGridNameCell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/CoAppDataGridSkeletonCell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/CoAppStandardDataGrid";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/CoAppDestructiveConfirmationModal";
import CoAppEditNameModal from "../../../global/components/modals/CoAppEditNameModal";

const pluginColumns = ["name", "status"];

export default function GroupPlugins() {
	const authHeader = useAuthHeader();
	const dummyData = getDataGridFakeData(pluginColumns);
	const { handleToastAlert } = useToastAlert();
	const { updateCoAppNavigation } = useCoAppNavigation();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("groupPlugins");
	const navigate = useNavigate();
	const organizationId = useSelector(selectOrganizationId);
	const { groupId } = useParams();
	const { pathname, state } = useLocation();

	const [groupPlugins, setGroupPlugins] = useState(dummyData);
	const [editGroupIsToggled, setEditGroupIsToggled] = useState(false);
	const [deleteGroupIsToggled, setDeleteGroupIsToggled] = useState(false);
	const [groupNameInput, setGroupNameInput] = useState("");
	const [groupNameError, setGroupNameError] = useState("");
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			editable: false,
			sortable: true,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			),
		},

		{
			field: "status",
			headerName: "Status",
			editable: false,
			sortable: true,
			flex: 0.5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={params.row.activeForGroup}
							disabled={params.row.active ? false : true}
							onChange={() => togglePlugin(params.row)}
						/>
					}
				/>
			),
		},
	];

	const togglePlugin = (plugin) => {
		axios.put(apiRoutes.updateGroupPlugin(groupId, plugin.id), { active: !plugin.activeForGroup }, { headers: authHeader })
			.then(() => {
				init();
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.GROUP_PLUGIN_TOGGLE_ERROR_MSG);
			});
	};

	const handleToggleGroupEditDialog = () => {
		setEditGroupIsToggled(!editGroupIsToggled);
		setGroupNameError("");
		setGroupNameInput("");
	};

	const handleGroupNameChange = (e) => {
		setGroupNameInput(e.target.value);
		setGroupNameError("");
	};

	const handleGroupNameChangeSubmit = () => {
		if (!groupNameInput) return;

		if (groupNameInput === state.name) {
			handleToggleGroupEditDialog();
			return;
		}

		axios.put(apiRoutes.updateGroup(groupId), { name: groupNameInput }, { headers: authHeader })
			.then(() => {
				setEditGroupIsToggled(false);
				updateCoAppNavigation(false, navigationItemReferences.groupNavItemIndex, groupNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setGroupNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Group"));
					return;
				}
				handleToggleGroupEditDialog();
				handleToastAlert(messageLevels.ERROR, messages.GROUP_NAME_UPDATE_ERROR_MSG);
			});
	};

	const handleToggleDeleteGroupDialog = () => {
		setDeleteGroupIsToggled(!deleteGroupIsToggled);
	};

	const confirmGroupDeletion = () => {
		axios.delete(apiRoutes.deleteGroup + "/" + groupId, {
			headers: authHeader
		}).then(() => {
			handleToastAlert(messageLevels.INFO, messages.GROUP_DELETED_SUCCESS_MSG(false));
			updateCoAppNavigation(true, navigationItemReferences.groupNavItemIndex);
			navigate(pages.groupManagement, { replace: true });
			handleToggleDeleteGroupDialog();
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.GROUP_DELETION_ERROR_MSG);
			handleToggleDeleteGroupDialog();
		});
	};

	const handleOrganizationalPluginRetrieval = async () => {
		let plugins = [];
		await axios.get(apiRoutes.getPlugins(organizationId), { headers: authHeader })
			.then((response) => {
				if (response.data.length === 0) return;

				const platforms = response.data;

				platforms.forEach(platform => {
					if (!platform.plugins) {
						return;
					}
					platform.plugins.forEach(plugin => {
						if (!plugin.active) return;
						plugins.push({
							id: plugin.id,
							name: plugin.name,
							groups: plugin.groups.map(group => group.name),
							active: plugin.active,
							platformId: platform.id,
						});
					});
				});
			})
			.catch((error) => {
				console.log(error);
			});
		return plugins;
	};

	const init = async () => {
		await axios.get(apiRoutes.getGroupPlugins(groupId), { headers: authHeader })
			.then(async (response) => {
				let orgPlugins = await handleOrganizationalPluginRetrieval();
				let plugins = orgPlugins.map(orgPlugin => {
					const groupPlugin = response.data.find(groupPlugin => groupPlugin.id === orgPlugin.id);
					return {
						...orgPlugin,
						activeForGroup: groupPlugin === undefined ? false : groupPlugin.active
					};

				});
				plugins = plugins.sort((a, b) => a.name.localeCompare(b.name));
				setGroupPlugins(plugins);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		init();
		handleInitializeSortModel();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} Plugin Management`}
				subResourceType="Plugin"
				resourceType="Group"
				addIsPresent={false}
				editIsPresent={true}
				editOnClickHandler={handleToggleGroupEditDialog}
				deleteIsPresent={true}
				deleteOnClickHandler={handleToggleDeleteGroupDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={groupPlugins}
				pinnedColumns={[]}
				allowSelection={false}
				targetResource="plugins"
				parentResource="group"
				parentResourceName={state.name}
				bulkDestructiveText="Remove"
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteGroupIsToggled}
				dialogTitle={`Delete ${state.name} group?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "group")}
				confirmClickHandler={confirmGroupDeletion}
				cancelClickHandler={handleToggleDeleteGroupDialog}
				actionText="Delete"
			/>
			<CoAppEditNameModal
				dialogOpen={editGroupIsToggled}
				dialogTitle="Edit group name"
				changeHandler={handleGroupNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleGroupNameChangeSubmit}
				cancelClickHandler={handleToggleGroupEditDialog}
				actionText="Save"
				editNameError={groupNameError}
				setEditNameError={setGroupNameError}
			/>
		</>
	);
}