import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import navigationItemReferences from "../../../constants/navigation-item-references";
import { getDataGridFakeData } from "../../../helpers/coappDataGridUtils";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../hooks/useDatagridSorting";
import useToastAlert from "../../../hooks/useToastAlert";
import { selectOrganizationIdentityProvider, selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import CoAppDatagridHeader from "../../global/components/datagrid/CoAppDatagridHeader";
import CoAppDataGridListCell from "../../global/components/datagrid/CoAppDataGridListCell";
import CoAppDataGridNameCell from "../../global/components/datagrid/CoAppDataGridNameCell";
import CoAppDataGridSkeletonCell from "../../global/components/datagrid/CoAppDataGridSkeletonCell";
import CoAppStandardDataGrid from "../../global/components/datagrid/CoAppStandardDataGrid";
import CoAppCreateGroupOrRoleModal from "../../global/components/modals/CoAppCreateGroupOrRoleModal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/CoAppDestructiveConfirmationModal";
import ThreeDotMenu from "../../global/components/ThreeDotMenu";

const roleColumns = ["actions", "name", "users", "permissions"];
const ssoAndScimEnabledRoleColumns = ["actions", "name", "users", "permissions", "idpgroups"];

export default function RoleManagement() {
	const organizationSsoAndScim = useSelector(selectOrganizationSsoAndScim);
	const dummyData = getDataGridFakeData(organizationSsoAndScim ? ssoAndScimEnabledRoleColumns : roleColumns);
	const authHeader = useAuthHeader();
	const { openResourceEditPage } = useCoAppNavigation();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("roles");
	const { handleToastAlert } = useToastAlert();
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);

	const [newRoleName, setNewRoleName] = useState("");
	const [roleCreationDialogOpen, setRoleCreationDialogOpen] = useState(false);
	const [roleDeleteDialogOpen, setRoleDeleteDialogOpen] = useState(false);
	const [roleSeed, setRoleSeed] = useState(0);
	const [roleBeingDeleted, setRoleBeingDeleted] = useState({});
	const [roleNameError, setRoleNameError] = useState("");
	const [roles, setRoles] = useState(dummyData);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: "Name",
			sortable: true,
			editable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			),
		},
		{
			field: "users",
			headerName: "Users",
			editable: false,
			sortable: false,
			flex: .5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Users" isUser={true} width={225} />}
				/>
			)
		},
		{
			field: "permissions",
			headerName: "Permissions",
			editable: false,
			sortable: false,
			flex: 1.25,
			renderCell: (params) => {
				let permissionsWithFriendlyNames = params.value === "" ? [] : params.value.map(permission => { return { name: permission.friendlyName }; });
				return (
					<CoAppDataGridSkeletonCell
						cellValue={params.value}
						cellToRender={
							< CoAppDataGridListCell items={permissionsWithFriendlyNames} resource="Permissions" />
						}
					/>
				);
			}
		},
		...(organizationSsoAndScim
			? [
				{
					field: "idpgroups",
					headerName: identityProviderName ? `${identityProviderName} Groups` : "Groups",
					editable: false,
					sortable: false,
					flex: 1.25,
					renderCell: (params) => (
						<CoAppDataGridSkeletonCell
							cellValue={params.value}
							cellToRender={<CoAppDataGridListCell items={params.value} resource={identityProviderName ? `${identityProviderName} Groups` : "Groups"} />}
						/>
					),
				},
			]
			: []),
		{
			field: "actions",
			type: "actions",
			flex: .1,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							<ThreeDotMenu
								options={[
									{
										name: "Edit",
										optionClickHandler: () => {
											openResourceEditPage(params, navigationItemReferences.roleNavItemIndex, "Roles");
										},
									},
									{
										name: "Duplicate",
										optionClickHandler: () => {
											duplicateRole(params.row.id);
										},
									},
									{
										name: "Delete",
										optionClickHandler: () => {
											setRoleBeingDeleted(params.row);
											setRoleDeleteDialogOpen(true);
										},
									},
								]}
							/>
						</div>
					}
				/>
			),
		},
	];

	const handleRoleNameChange = (e) => {
		setNewRoleName(e.target.value);
		setRoleNameError("");
	};

	const createNewRole = () => {
		if (!newRoleName) {
			setRoleNameError("Required *");
			return;
		}

		let role = { name: newRoleName };

		axios.post(apiRoutes.createRole, role, { headers: authHeader })
			.then(() => {
				initRoleManagement();
				handleToastAlert(messageLevels.SUCCESS, messages.ROLE_CREATED_SUCCESS_MSG);
				toggleAddNewDialog();
			})
			.catch((err) => {
				if (err.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleAddNewDialog();
				handleToastAlert(messageLevels.ERROR, messages.ROLE_CREATION_ERROR_MSG);
			});
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleBeingDeleted.id, { headers: authHeader })
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.ROLE_DELETION_SUCCESS_MSG(false));
				setRoleSeed(Math.random());
				handleCloseRoleDeletionDialog();
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.ROLE_DELETION_ERROR_MSG);
				handleCloseRoleDeletionDialog();
			});
	};

	const bulkDeleteRoles = () => {
		const deleteData = {
			roleIds: selectedRoles
		};

		axios.delete(apiRoutes.bulkDeleteRoles, { headers: authHeader, data: deleteData })
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.ROLE_DELETION_SUCCESS_MSG(selectedRoles.length > 1));
				setRoleSeed(Math.random());
				setBulkDeleteDialogOpen(false);
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.ROLE_DELETION_ERROR_MSG);
				setBulkDeleteDialogOpen(false);
			});
	};

	const duplicateRole = (id) => {
		axios.post(apiRoutes.copyRole(id), {}, { headers: authHeader })
			.then(() => {
				setRoleSeed(Math.random());
			})
			.catch((err) => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.ROLE_DUPLICATION_ERROR_MSG);
			});
	};

	const handleCloseRoleDeletionDialog = () => {
		setRoleBeingDeleted({});
		setRoleDeleteDialogOpen(false);
	};

	const initRoleManagement = () => {
		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then((res) => {
				setRoles(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const toggleAddNewDialog = () => {
		setRoleCreationDialogOpen(!roleCreationDialogOpen);
		setNewRoleName("");
		setRoleNameError("");
	};

	useEffect(() => {
		initRoleManagement();
		handleInitializeSortModel();
	}, [roleSeed]);

	return (
		<>
			<CoAppDatagridHeader
				title="Roles"
				resourceType="Role"
				addIsPresent={true}
				addOnClickHandler={toggleAddNewDialog}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={roles}
				pinnedColumns={["actions", "__check__", "name", "status"]}
				targetResource="roles"
				selectionModel={selectedRoles}
				setSelectionModel={setSelectedRoles}
				handleModalToggle={() => setBulkDeleteDialogOpen(true)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppCreateGroupOrRoleModal
				cancelClickHandler={toggleAddNewDialog}
				changeHandler={handleRoleNameChange}
				dialogOpen={roleCreationDialogOpen}
				nameError={roleNameError}
				confirmClickHandler={createNewRole}
				isRole={true}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={roleDeleteDialogOpen}
				dialogTitle={`Delete ${roleBeingDeleted.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(roleBeingDeleted.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={handleCloseRoleDeletionDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDeleteDialogOpen}
				dialogTitle={`Delete selected ${selectedRoles.length > 1 ? "roles" : "role"}?`}
				dialogMessage={"Are you sure you want to bulk delete all the selected roles? This action cannot be undone."}
				confirmClickHandler={bulkDeleteRoles}
				cancelClickHandler={() => setBulkDeleteDialogOpen(false)}
				actionText="Delete"
			/>
		</>
	);
}
