import { useEffect } from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton, CoAppCloseIcon, CoAppDialog } from "../../styled/global.styled";
import CoAppMultiSelect from "../inputs/CoAppMultiSelect";

export default function CoAppAddItemsToListModal(props) {
	const {
		addButtonTitle, addItemsIsToggled, addItemsToCollection,
		addItemsToNewArray, dialogTitle, inputLabel,
		items, itemsToAdd, toggleDialog, loading
	} = props;

	useEffect(() => {
	}, [addItemsIsToggled]);

	return (
		<CoAppDialog open={addItemsIsToggled} onClose={toggleDialog} PaperProps={{ sx: { borderRadius: "8px", padding: "8px" } }}>
			<DialogTitle variant="h6" sx={{ width: "550px" }}>{dialogTitle}</DialogTitle>
			<CoAppCloseIcon onClick={toggleDialog} top={8} left={530} />
			<DialogContent>
				<CoAppMultiSelect
					customStyles={{ width: "500px" }}
					loading={loading}
					disabled={false}
					isFreeSolo={false}
					label={inputLabel}
					options={items.map((item) => item.name)}
					value={itemsToAdd.map((item) => item.name)}
					onChange={(e, value, reason) => addItemsToNewArray(value)}
					hasToolTip={dialogTitle.toLowerCase().includes("user")} // If the title includes user (add users to group/role), we enable special tag rendering (i.e, show users name + email on hover)
					rawOptions={items} // Raw options are used to get the email of the user when the user hovers over the tag
				/>
			</DialogContent>
			<DialogActions sx={{ marginRight: "15px", marginBottom: "10px" }}>
				<CoAppCancelTextButton onClick={toggleDialog}>Cancel</CoAppCancelTextButton>
				<CoAppActionButton onClick={addItemsToCollection}>{addButtonTitle}</CoAppActionButton>
			</DialogActions>
		</CoAppDialog >
	);
}