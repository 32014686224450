import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectNavState } from "../../redux/navigationSlice";

import { CoAppDrawer, CoAppNavLogoContainer } from "./styled/coapp-nav-tree.styled";
import CoAppSettingsNavTree from "./CoAppSettingsNavTree";

export default function CoAppNavDrawer() {
	const navigate = useNavigate();
	const navState = useSelector(selectNavState);
	const location = useLocation();

	const handleRedirect = (page, options) => {
		navigate(page, options);
	};

	useEffect(() => {
	}, [navState, location.pathname]);

	return (
		<CoAppDrawer anchor="left" variant="permanent">
			<CoAppNavLogoContainer>
				<a href="/rules/library">
					{process.env.REACT_APP_VERSION ?
						<img href="/rules/library" src="https://coapp-public-assets.s3.amazonaws.com/CoApp-Logo-COLOR-RGB.png" alt="CoApp Logo" style={{ height: "40px", width: "115.62px" }} />
						:
						<img href="/rules/library" src="/images/CoApp-Logo-COLOR-RGB.png" alt="CoApp Logo" style={{ height: "40px", width: "115.62px" }} />
					}
				</a>
			</CoAppNavLogoContainer>
			<CoAppSettingsNavTree treeData={navState} handleRedirect={handleRedirect} />
		</CoAppDrawer>
	);
}