import { Fragment, useState } from "react";
import { Alert, Dialog, Typography } from "@mui/material";

import CoAppMultiSelect from "../global/components/inputs/CoAppMultiSelect";

export default function TagInput(props) {

	const [tagAlertOpen, setTagAlertOpen] = useState(false);

	/**
	 * Listen for any change in tag field.
	 * 1. Validate the new tag is not over 20 characters, pop alert and clear text if so.
	 * 2. Inhibit MUI"s Autocomplete from removing a selected tag if it is selected again, while still allowing removal.
	 * 3. If checks are passed, add tag to redux state.
	 */
	const handleChange = (e, newValue) => {
		if (newValue.length !== 0) {
			if (newValue[newValue.length - 1].length > 20) {
				setTagAlertOpen(true);
				return;
			}
		}
		if (props.tags.includes(newValue[newValue.length - 1]) && newValue.length > props.tags.length) {
			return;
		} else {
			props.createTags(newValue);
		}
	};

	const handleTagAlertToggle = () => {
		tagAlertOpen ? setTagAlertOpen(false) : setTagAlertOpen(true);
	};

	return (
		<Fragment>
			<CoAppMultiSelect
				isFreeSolo={true}
				label="Tags"
				onChange={handleChange}
				options={props.tagsInCurrentOrg.map(tag => tag.name)}
				textFieldWidth={ props.textFieldWidth ? props.textFieldWidth : "100%" }
				value={props.tags}
			/>
			<Typography variant="body3">Select from existing tags or create your own by typing and pressing &apos;ENTER&apos; when finished.</Typography>
			<Dialog onClose={handleTagAlertToggle} open={tagAlertOpen}>
				<Alert severity="error">Tags must be 20 characters or less.</Alert>
			</Dialog>
		</Fragment>
	);
}
